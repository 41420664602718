import React, { useEffect, useRef, useState } from "react";
import logo from "../assets/img/logo.svg";


const Navbar4 = () => {
  const [open, setOpen] = useState(false);
  const [searchFormOpen, setSearchFormOpen] = useState(false);

  const trigger = useRef(null);
  const searchForm = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!searchForm.current) return;
      if (
        !searchFormOpen ||
        searchForm.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSearchFormOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!searchFormOpen || keyCode !== 27) return;
      setSearchFormOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <header className="flex w-full items-center bg-white">
      <div className="container mx-auto">
        <div className="relative -mx-4 flex items-center justify-between">
          <div className="w-60 max-w-full px-4">
            <a href="/#" className="block w-full py-5 lg:py-3">
              <img
                src={logo}
                alt="Peresec logo"
                className="w-full"
              />
            </a>
          </div>
          <div className="flex w-full items-center justify-between px-4">
            <div className="w-full">
              <button
                onClick={() => setOpen(!open)}
                className={` ${
                  open && "navbarTogglerActive"
                } ring-primary absolute right-4 top-1/2 block -translate-y-1/2 rounded-lg px-3 py-[6px] focus:ring-2 lg:hidden`}
              >
                <span className="bg-body-color relative my-[6px] block h-[2px] w-[30px]"></span>
                <span className="bg-body-color relative my-[6px] block h-[2px] w-[30px]"></span>
                <span className="bg-body-color relative my-[6px] block h-[2px] w-[30px]"></span>
              </button>
              <nav
                className={`absolute right-4 z-50 top-full w-full max-w-[250px] justify-center rounded-lg bg-white py-5 px-6 shadow lg:static lg:flex lg:w-full lg:max-w-full lg:bg-transparent lg:py-0 lg:shadow-none ${
                  !open && "hidden"
                } `}
              >
                <ul className="block lg:flex">
                  <ListItem NavLink="/bank-holidays">Bank Holidays</ListItem>
                  <ListItem NavLink="/withholding-tax">Withholding Tax</ListItem>
                  <ListItem NavLink="/peresec-offshore-markets">Offshore Markets</ListItem>
                  <ListItem NavLink="/corporate-actions">Corporate Actions</ListItem>
                  <ListItem NavLink="/corporate-news">News</ListItem>
                  {/* <ListItem NavLink="/pricing">Pricing</ListItem> */}

                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar4;

const ListItem = ({ children, NavLink }) => {
  return (
    <>
      <li>
        <a
          href={NavLink}
          className="text-dark hover:text-primary flex py-2 text-base font-medium lg:mx-6 lg:inline-flex lg:py-6"
        >
          {children}
        </a>
      </li>
    </>
  );
};
