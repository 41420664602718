import React, { useState, useEffect } from 'react';
import axios from 'axios';
import exchangeData from './Pricing/exchangeData.json'
import exchangeData2 from './Pricing/exchangeData2.json'
import EODDataTable from './EODDataTable';
import LiveDataTable from './LiveDataTable';
import IntraDayTable from './IntraDayTable';

function getFormattedCurrentDate() {
  const year = '2023'
  const month = '11'
  const day = '01'
  return `${year}-${month}-${day}`;
}

const headers = [
  { name: 'Name', styles: 'min-w-[210px]' },
  { name: 'Code', styles: 'min-w-[130px]' },
  { name: 'Country', styles: 'min-w-[140px]' },
  { name: 'Currency', styles: 'min-w-[120px]' },
  { name: 'Type', styles: 'min-w-[120px]' },
  { name: 'Exchange', styles: 'min-w-[120px]' },
  { name: 'End of Day', styles: 'min-w-[120px]' },
  { name: 'Real Time', styles: 'min-w-[120px]' },
  { name: 'Intra Day', styles: 'min-w-[120px]' },
];


const Table2 = () => {
  const [rowData, setRowData] = useState(null);
  const [selectedStock, setSelectedStock] = useState('Please select');
  const [TestData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalItems = TestData.length;
  const [totalPages, setTotalPages] = useState(Math.ceil(totalItems.length / itemsPerPage));
  const [selectedDataType, setSelectedDataType] = useState(null);


  

  useEffect(() => {
    async function fetchData() {
      try {
        const apiData1 = exchangeData;
        const apiData2 = exchangeData2;

        // Concatenate the two data arrays
        const combinedData = apiData1.concat(apiData2);
        // Alternatively, you can use the spread operator
        // const combinedData = [...apiData1, ...apiData2];

        setTableData(combinedData);
        setFilteredData(combinedData);
        setTotalPages(Math.ceil(combinedData.length / itemsPerPage));
      } catch (error) {
        setError(error);
      }
    }

    fetchData();
  }, []);


  if (error) {
    return <div>An error occurred: {error.message}</div>;
  }

  const newNoPages = (dataNew) => {

    const total = Math.ceil(dataNew.length / itemsPerPage);
    setTotalPages(total);
    console.log("TOTAL " + total)
  }

  const paginateData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return TestData.slice(startIndex, endIndex);
  };

  const handleNextPage = () => {

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      console.log(totalPages + "HERE")
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const [selectedExchange, setselectedExchange] = useState("EXCHANGE");
  const [selectedType, setselectedType] = useState("TYPE");
  const [selectedInstrumentCode, setSelectedInstrumentCode] = useState("CODE");

  const [startDate, setStartDate] = useState(getFormattedCurrentDate());
  const [endDate, setEndDate] = useState('2025-12-31');

  const [searchInput, setSearchInput] = useState('');

  const handleExchangeChange = (selectedExchange) => {
    setselectedExchange(selectedExchange);
    setCurrentPage(1);
  };

  const handleSearchInputChange = (searchInput) => {
    setSearchInput(searchInput);
    setCurrentPage(1);
  }

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Additional useEffect to handle selectedDataType changes
    if (selectedDataType) {
      // Perform actions or additional logic based on selectedDataType
      console.log("Selected Data Type: ", selectedDataType);
    }
  }, [selectedDataType]);

  const handleEODDataClick = async (stockSymbol, exchange, country) => {
    try {
      setSelectedDataType("EOD");
      setLoading(true);
      // Conditionally set the exchange based on the country
      const exchange = country === 'USA' ? 'US' : 'NASDAQ';
      const apiURL = `https://eodhd.com/api/eod/${stockSymbol}?exchange=${exchange}&api_token=65782533b07704.64028755&fmt=json`;
      const response = await axios.get(apiURL);
      setRowData(response.data);
      setSelectedStock(stockSymbol);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleLiveDataClick = async (stockSymbol, exchange, country) => {
    try {
      setSelectedDataType("Live");
      setLoading(true);
      // Conditionally set the exchange based on the country
      console.log("LIVE COUNTRY: " + country)
      const exchange = country === 'USA' ? 'US' : 'NASDAQ';
      console.log("LIVE EXCHANGE: " + exchange)
      const apiURL = `https://eodhd.com/api/real-time/${stockSymbol}?exchange=${exchange}&api_token=65782533b07704.64028755&fmt=json`;
      const response = await axios.get(apiURL);
      const liveDataArray = [response.data];
      setRowData(liveDataArray);
      setSelectedStock(stockSymbol);
      console.log("LIVE API CALL: " + apiURL)
      console.log("LIVE FETCH CONFIRM ", response.data);
    } catch (error) {
      console.error('Error fetching stock data:', error);
    } finally {
      setLoading(false);
    }
  };


  const handleIntraDayDataClick = async (stockSymbol, exchange, country) => {
    try {
      setSelectedDataType("Intra");
      setLoading(true);
      // Conditionally set the exchange based on the country
      const exchange = country === 'USA' ? 'US' : 'NASDAQ';
      const apiURL = `https://eodhd.com/api/intraday/${stockSymbol}?interval=5m&exchange=${exchange}&api_token=65782533b07704.64028755&fmt=json&from=1704087165&to=1704691965`;
      const response = await axios.get(apiURL);
      setRowData(response.data);
      setSelectedStock(stockSymbol);
      console.log('INTRA DAY: ', response.data);
    } catch (error) {
      console.error('Error fetching stock data:', error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <section className='bg-white lg:py-[20px]'>
      <div className='container mx-auto'>
        <div className='-mx-4 flex flex-wrap'>
          <div className='w-full px-4'>
            <div className='max-w-full overflow-x-auto rounded-xl shadow-lg'>
              <TableTop
                data={filteredData}
                headers={headers}
                onExchangeChange={handleExchangeChange}
                onSearchInput={handleSearchInputChange}
              />
              <table className='max-w-full table-auto'>
                <TableHead
                  data={filteredData}
                  headers={headers}
                />
                <TableBody
                  data={filteredData}
                  selectedExchange={selectedExchange}
                  searchInput={searchInput}
                  selectedType={selectedType}
                  selectedInstrumentCode={selectedInstrumentCode}
                  startDate={startDate}
                  endDate={endDate}
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  newNoPages={newNoPages}
                  setRowData={setRowData} // Pass setRowData to TableBody
                  setSelectedStock={setSelectedStock}
                  handleEODDataClick={handleEODDataClick}  // Pass the click handler
                  handleLiveDataClick={handleLiveDataClick}  // Pass the click handler
                  handleIntraDayDataClick={handleIntraDayDataClick}
                />
                <TableBottom
                  handlePreviousPage={handlePreviousPage}
                  handleNextPage={handleNextPage}
                  currentPage={currentPage}
                  totalPages={totalPages}
                />
              </table>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <section className='bg-white dark:bg-dark py-20 lg:py-[120px]'>
          <div className='container mx-auto'>
            <p>Loading {selectedDataType} data...</p>
          </div>
        </section>
      )}
      {!loading && selectedDataType && rowData && rowData.length > 0 && (
        <>
          {selectedDataType === "EOD" && (
            <EODDataTable
              selectedStock={selectedStock}
              tableData={rowData}
            />
          )}
          {selectedDataType === "Live" && (
            <LiveDataTable
              selectedStock={selectedStock}
              tableData={rowData}
            />
          )}
          {selectedDataType === "Intra" && (
            <IntraDayTable
              selectedStock={selectedStock}
              tableData={rowData}
            />
          )}
        </>
      )}
      {!loading && selectedDataType && rowData && rowData.length === 0 && (
        <section className='bg-white dark:bg-dark py-20 lg:py-[120px]'>
          <div className='container mx-auto'>
            <p>No {selectedDataType} data available for this stock, please select another</p>
          </div>
        </section>
      )}

      {!loading && !selectedDataType && (
        <section className='bg-white dark:bg-dark py-20 lg:py-[120px]'>
          <div className='container mx-auto'>
            <p>Please select a stock above</p>
          </div>
        </section>
      )}
    </section>
  );
};

const TableHead = ({ headers }) => {

  return (
    <thead>
      <tr className='bg-[#F9FAFB] text-left'>
        {headers.map((header, index) => (
          <th
            className={` px-4 first:pl-11 last:pr-11 text-base font-medium uppercase text-dark ${header.styles}`}
            key={index}
          >
            {header.name}
          </th>
        ))}
      </tr>
    </thead>
  );
};

const TableBody = ({ data, searchInput, currentPage, newNoPages, setRowData, handleEODDataClick, handleLiveDataClick, handleIntraDayDataClick }) => {

  const [pageSize, setPageSize] = useState(10);

  const filtered = (searchInput !== '')
    ? data.filter((row) => {
      return (
        (searchInput === '' || row.Name.toLowerCase().includes(searchInput) || row.Code.toLowerCase().includes(searchInput))
      );
    })
    : data;


  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = filtered.slice(startIndex, endIndex);

  newNoPages(filtered);

  return (
    <tbody>
      {paginatedData.map((row, index) => (
        <tr key={index}>
          <td className='border-t border-[#eee] py-5 px-4 pl-11'>
            <div className='flex items-center'>
              <div>
                <h5 className='text-dark text-sm font-medium'>{row.Name}</h5>
              </div>
            </div>
          </td>
          <td className='border-t border-[#eee] py-5 px-4'>
            <p className='text-body-color text-sm'>{row.Code}</p>
          </td>
          <td className='border-t border-[#eee] py-5 px-4'>
            <p className='text-body-color text-sm'>{row.Country}</p>
          </td>
          <td className='border-t border-[#eee] py-5 px-4'>
            <p className='text-body-color text-sm'>{row.Currency}</p>
          </td>
          <td className='border-t border-[#eee] py-5 px-4'>
            <p className='text-body-color text-sm'>{row.Type}</p>
          </td>
          <td className='border-t border-[#eee] py-5 px-4'>
            <p className='text-body-color text-sm'>{row.Exchange}</p>
          </td>
          <td onClick={() => handleEODDataClick(`${row.Code}`, row.Exchange, row.Country)} className='text-dark border-b border-[#E8E8E8] bg-white dark:border-dark dark:bg-dark-2 dark:text-dark-7 py-5 px-2 text-center text-base font-medium'>
            <a href='#bottom' className='inline-block px-6 py-2.5 border rounded-md border-primary text-primary hover:bg-primary hover:text-white font-medium'>
              Get End of Day Data
            </a>
          </td>
          <td onClick={() => handleLiveDataClick(`${row.Code}`, row.Exchange, row.Country)} className='text-dark border-b border-[#E8E8E8] bg-white dark:border-dark dark:bg-dark-2 dark:text-dark-7 py-5 px-2 text-center text-base font-medium'>
            <a href='#bottom' className='inline-block px-6 py-2.5 border rounded-md border-primary text-primary hover:bg-primary hover:text-white font-medium'>
              Get Live Data
            </a>
          </td>
          <td onClick={() => handleIntraDayDataClick(`${row.Code}`, row.Exchange, row.Country)} className='text-dark border-b border-[#E8E8E8] bg-white dark:border-dark dark:bg-dark-2 dark:text-dark-7 py-5 px-2 text-center text-base font-medium'>
            <a href='#bottom' className='inline-block px-6 py-2.5 border rounded-md border-primary text-primary hover:bg-primary hover:text-white font-medium'>
              Get Intra Day Data
            </a>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

const TableTop = ({ headers, data, onExchangeChange, onSearchInput }) => {
  const paginatedData = data;
  const [exchangesArray, setExchangesArray] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  var distinctExchangeValues = Array.from(new Set(paginatedData.map(row => row.Exchange)));

  const handleSearchInputChange = (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchInput(searchText);
    onSearchInput(searchText)
    console.log("SEARCHED: " + searchText);
  };

  return (
    <thead>
      <tr className='bg-[#F9FAFB] text-left'>
        {headers.map((header, index) => (
          <th
            className={`py-1 first:pl-11 last:pr-5 text-base font-medium uppercase text-dark `}
            key={index}
          >
            {header.name === 'Name' && (
              <>
              </>
            )}
            {header.name === 'Code' && (
              <>
                <input
                  type="text"
                  placeholder="Try 'AAPL' or 'Apple'..."
                  value={searchInput}
                  onChange={handleSearchInputChange}
                  className="w-full bg-transparent py-[14px] pl-2 pr-[200px] text-base text-body-color outline-none dark:text-dark-6"
                />

              </>

            )

            }
          </th>
        ))}
      </tr>
    </thead>

  );
};

const TableBottom = ({ handlePreviousPage, handleNextPage, currentPage, totalPages }) => {
  return (

    <div id='bottom' className='flex items-center justify-between p-5 sm:px-6'>

      <div className='flex items-center justify-end space-x-3'>
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className={`flex h-[30px] w-[30px] items-center justify-center rounded border border-stroke bg-[#FAFBFF] text-body-color hover:border-primary hover:bg-primary hover:text-white ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : ''}`}>
          <svg
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M14.25 10C14.8023 10 15.25 9.55228 15.25 9C15.25 8.44771 14.8023 8 14.25 8L14.25 10ZM3.75 9L3.04289 8.29289C2.65237 8.68342 2.65237 9.31658 3.04289 9.70711L3.75 9ZM8.29289 14.9571C8.68342 15.3476 9.31658 15.3476 9.70711 14.9571C10.0976 14.5666 10.0976 13.9334 9.70711 13.5429L8.29289 14.9571ZM9.7071 4.45711C10.0976 4.06658 10.0976 3.43342 9.7071 3.04289C9.31658 2.65237 8.68342 2.65237 8.29289 3.04289L9.7071 4.45711ZM14.25 8L3.75 8L3.75 10L14.25 10L14.25 8ZM9.70711 13.5429L4.4571 8.29289L3.04289 9.70711L8.29289 14.9571L9.70711 13.5429ZM4.4571 9.70711L9.7071 4.45711L8.29289 3.04289L3.04289 8.29289L4.4571 9.70711Z'
              fill='currentColor'
            />
          </svg>
        </button>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={`flex h-[30px] w-[30px] items-center justify-center rounded border border-stroke bg-[#FAFBFF] text-body-color hover:border-primary hover:bg-primary hover:text-white ${currentPage === totalPages ? 'cursor-not-allowed opacity-50' : ''}`}>
          <svg
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M3.75 8C3.19772 8 2.75 8.44772 2.75 9C2.75 9.55229 3.19772 10 3.75 10V8ZM14.25 9L14.9571 9.70711C15.3476 9.31658 15.3476 8.68342 14.9571 8.29289L14.25 9ZM9.70711 3.04289C9.31658 2.65237 8.68342 2.65237 8.29289 3.04289C7.90237 3.43342 7.90237 4.06658 8.29289 4.45711L9.70711 3.04289ZM8.29289 13.5429C7.90237 13.9334 7.90237 14.5666 8.29289 14.9571C8.68342 15.3476 9.31658 15.3476 9.70711 14.9571L8.29289 13.5429ZM3.75 10H14.25V8H3.75V10ZM8.29289 4.45711L13.5429 9.70711L14.9571 8.29289L9.70711 3.04289L8.29289 4.45711ZM13.5429 8.29289L8.29289 13.5429L9.70711 14.9571L14.9571 9.70711L13.5429 8.29289Z'
              fill='currentColor'
            />
          </svg>
        </button>
      </div>
    </div>
  )
}


export default Table2;

