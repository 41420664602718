import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Papa from 'papaparse';

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

// Define the SingleSidebarCard component (from its file)
const SingleSidebarCard = ({ title, children }) => {
  return (
    <div>
      <div >
        <h3 className="text-base font-semibold text-black sm:text-lg lg:text-base xl:text-lg">
          {title}
        </h3>
      </div>
      <div className="space-y-4 ">{children}</div>
    </div>
  );
};

function getFormattedCurrentDate() {
  const year = currentYear
  const month = '01'
  const day = '01'
  return `${year}-${month}-${day}`;
}

const headers = [
  { name: 'Exchange', styles: 'min-w-[210px]' },
  { name: 'Type', styles: 'min-w-[130px]' },
  { name: 'Instrument Code', styles: 'min-w-[130px]' },
  { name: 'Date', styles: 'min-w-[140px]' },
  { name: 'Ex-Date', styles: 'min-w-[120px]' },
  { name: 'Eligibility Date', styles: 'min-w-[120px]' },
  { name: 'Payable Date', styles: 'min-w-[130px]' },
  { name: 'Rate', styles: 'min-w-[140px]' },

];


const Table2 = () => {

  const [TestData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalItems = TestData.length;
  const [totalPages, setTotalPages] = useState(Math.ceil(totalItems.length / itemsPerPage)); // Initialize with 1 or use a default value



  useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios.get('https://grateful-presence-5c73bfcfd5.strapiapp.com/api/corporate-action-diaries');
        const apiData = res.data.data[0].attributes.data;
        setTableData(apiData);
        setFilteredData(apiData);
        setTotalPages(Math.ceil(apiData.length / itemsPerPage)); // Update totalPages based on apiData length
      } catch (error) {
        setError(error);
      }
    }

    fetchData();
  }, []);

  if (error) {
    return <div>An error occurred: {error.message}</div>;
  }
  
  const newNoPages = (dataNew) => {
    
    const total = Math.ceil(dataNew.length / itemsPerPage);
    setTotalPages(total);
    console.log("TOTAL " + total)
  }

  const paginateData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return TestData.slice(startIndex, endIndex);
  };

  const handleNextPage = () => {

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      console.log(totalPages + "HERE")
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const paginatedData = paginateData();

  const convertToCSV = (data) => {
    const csv = Papa.unparse(data);
    return csv;
  }

  function reverseAndFormatDate(dateString) {
    // Assuming the input format is 'dd/mm/yyyy'
    const parts = dateString.split('/');
    const reversedDate = parts[2] + '-' + parts[1] + '-' + parts[0];
    return reversedDate;
  }

  const downloadCSV = async () => {
    try {
      const res = await axios.get('https://grateful-presence-5c73bfcfd5.strapiapp.com/api/corporate-action-diaries');
      const apiData = res.data.data[0].attributes.data;
  
      // Apply your filtering logic here
      const filteredData = apiData.filter((row) => {
        return (
          (selectedExchange === 'EXCHANGE' || row.Exchange === selectedExchange) &&
          (selectedType === 'TYPE' || row.Type === selectedType) &&
          (selectedInstrumentCode === 'CODE' || row.InstrumentCode === selectedInstrumentCode) &&
          (!startDate || reverseAndFormatDate(row.Date) >= startDate) &&
          (!endDate || reverseAndFormatDate(row.Date) <= endDate)
        );
      });
  
      const csvData = convertToCSV(filteredData);
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
  
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'corporate-actions.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };
  



  const [selectedExchange, setselectedExchange] = useState("EXCHANGE");
  const [selectedType, setselectedType] = useState("TYPE");
  const [selectedInstrumentCode, setSelectedInstrumentCode] = useState("CODE");

  const [startDate, setStartDate] = useState(getFormattedCurrentDate());
  const [endDate, setEndDate] = useState(`${currentYear}-12-31`);



  const handleExchangeChange = (selectedExchange) => {
    setselectedExchange(selectedExchange);
    setCurrentPage(1); 
  };

  const handleTypeChange = (selectedType) => {
    setselectedType(selectedType);
  };

  const handleInstrumentCodeChange = (selectedInstrumentCode) => {
    setSelectedInstrumentCode(selectedInstrumentCode);
  };

  const handleStartDateChange = (startDate) => {
    setStartDate(startDate);
  };

  const handleEndDateChange = (endDate) => {
    setEndDate(endDate);
  };

  return (
    <section className='bg-white lg:py-[20px]'>
      <div className='container mx-auto'>
        <div className='-mx-4 flex flex-wrap'>
          <div className='w-full px-4'>
            <div className='max-w-full overflow-x-auto rounded-xl shadow-lg'>
              <TableTop
                downloadCSV={downloadCSV}
                data={filteredData}
                headers={headers}
                onExchangeChange={handleExchangeChange}
                onTypeChange={handleTypeChange}
                onInstrumentCodeChange={handleInstrumentCodeChange}
                onStartDateChange={handleStartDateChange}
                onEndDateChange={handleEndDateChange}
              />
              <table className='max-w-full table-auto'>
                <TableHead
                  data={filteredData}
                  headers={headers}
                />
                <TableBody
                  data={filteredData}
                  selectedExchange={selectedExchange}
                  selectedType={selectedType}
                  selectedInstrumentCode={selectedInstrumentCode}
                  startDate={startDate}
                  endDate={endDate} 
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  newNoPages={newNoPages}/>
                <TableBottom
                  handlePreviousPage={handlePreviousPage}
                  handleNextPage={handleNextPage}
                  currentPage={currentPage}
                  totalPages={totalPages}
                />
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const TableHead = ({ headers }) => {

  return (
    <thead>
      <tr className='bg-[#F9FAFB] text-left'>
        {headers.map((header, index) => (
          <th
            className={` px-4 first:pl-11 last:pr-11 text-base font-medium uppercase text-dark ${header.styles}`}
            key={index}
          >
            {header.name}
          </th>
        ))}
      </tr>
    </thead>
  );
};

const TableBody = ({ data, selectedExchange, selectedType, selectedInstrumentCode, startDate, endDate, currentPage, newNoPages }) => {
  const [pageSize, setPageSize] = useState(10);



  function reverseAndFormatDate(dateString) {
    // Assuming the input format is 'dd/mm/yyyy'
    const parts = dateString.split('/');
    const reversedDate = parts[2] + '-' + parts[1] + '-' + parts[0];
    return reversedDate;
  }

  const filtered = (selectedExchange !== 'EXCHANGE' || selectedType !== 'TYPE' || selectedInstrumentCode !== 'CODE' || startDate !== '' || endDate !== '')
    ? data.filter((row) => {
      return (
        (selectedExchange === 'EXCHANGE' || row.Exchange === selectedExchange) &&
        (selectedType === 'TYPE' || row.Type === selectedType) &&
        (selectedInstrumentCode === 'CODE' || row.InstrumentCode === selectedInstrumentCode) &&
        (!startDate || reverseAndFormatDate(row.Date) >= startDate) &&
        (!endDate || reverseAndFormatDate(row.Date) <= endDate)
      );
    })
    : data;

    
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = filtered.slice(startIndex, endIndex);  

  newNoPages(filtered);

  return (
    <tbody>
      {paginatedData.map((row, index) => (
        <tr key={index}>
          <td className='border-t border-[#eee] py-5 px-4 pl-11'>
            <div className='flex items-center'>
              <div>
                <h5 className='text-dark text-sm font-medium'>{row.Exchange}</h5>
              </div>
            </div>
          </td>
          <td className='border-t border-[#eee] py-5 px-4'>
            <p className='text-body-color text-sm'>{row.Type}</p>
          </td>
          <td className='border-t border-[#eee] py-5 px-4'>
            <p className='text-body-color text-sm'>{row.InstrumentCode}</p>
          </td>
          <td className='border-t border-[#eee] py-5 px-4'>
            <p className='text-body-color text-sm'>{row.Date}</p>
          </td>
          <td className='border-t border-[#eee] py-5 px-4'>
            <p className='text-body-color text-sm'>{row.ExDate}</p>
          </td>
          <td className='border-t border-[#eee] py-5 px-4'>
            <p className='text-body-color text-sm'>{row.EligibilityDate}</p>
          </td>
          <td className='border-t border-[#eee] py-5 px-4'>
            <p className='text-body-color text-sm'>{row.PayableDate}</p>
          </td>
          <td className='border-t border-[#eee] py-5 px-4'>
            <p className='text-body-color text-sm'>{row.Rate}</p>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

const TableTop = ({ downloadCSV, headers, data, onExchangeChange, onTypeChange, onInstrumentCodeChange, onStartDateChange, onEndDateChange }) => {
  const paginatedData = data;
  const [selectedExchange, setSelectedExchange] = useState("EXCHANGE");
  const [selectedType, setSelectedType] = useState("TYPE");
  const [selectedInstrumentCode, setSelectedInstrumentCode] = useState("CODE");

  const [startDate, setStartDate] = useState(getFormattedCurrentDate());
  const [endDate, setEndDate] = useState(`${currentYear}-12-31`);


  const [typesArray, setTypesArray] = useState([]);
  const [exchangesArray, setExchangesArray] = useState([]);
  const [instrumentCodeArray, setInstrumentCodeArray] = useState([]);


  var distinctExchangeValues = Array.from(new Set(paginatedData.map(row => row.Exchange)));
  var distinctTypeValues = Array.from(new Set(paginatedData.map(row => row.Type)));
  var distinctInstrumentCodeValues = Array.from(new Set(paginatedData.map(row => row.InstrumentCode)));

  const handleSelectedExchange = (event) => {
    const selectedExchange = event.target.value;
    setSelectedExchange(selectedExchange);
    onExchangeChange(selectedExchange);

    exchangeSelected(selectedExchange);
  };

  const handleSelectedType = (event) => {
    const selectedType = event.target.value;
    setSelectedType(selectedType);
    onTypeChange(selectedType);

    typeSelected(selectedType);
  };

  const handleSelectedInstrumentCode = (event) => {
    const selectedInstrumentCode = event.target.value;
    setSelectedInstrumentCode(selectedInstrumentCode);
    onInstrumentCodeChange(selectedInstrumentCode);

    instrumentSelected(selectedInstrumentCode);

  };

  const exchangeSelected = (selectedExchange) => {
    if (selectedExchange === "EXCHANGE") {
      setTypesArray(Array.from(new Set(paginatedData.map(row => row.Type))))
      setInstrumentCodeArray(Array.from(new Set(paginatedData.map(row => row.InstrumentCode))))
    } else {
      setTypesArray(Array.from(new Set(paginatedData.filter(row => (row.Exchange === selectedExchange)).map(row => row.Type))))
      setInstrumentCodeArray(Array.from(new Set(paginatedData.filter(row => (row.Exchange === selectedExchange)).map(row => row.InstrumentCode))))
    }
  };


  const typeSelected = (selectedType) => {
    if (selectedType === "TYPE") {
      setExchangesArray(Array.from(new Set(paginatedData.map(row => row.Exchange))))
      setInstrumentCodeArray(Array.from(new Set(paginatedData.map(row => row.InstrumentCode))))
    } else {
      setExchangesArray(Array.from(new Set(paginatedData.filter(row => row.Type === selectedType).map(row => row.Exchange))))
      setInstrumentCodeArray(Array.from(new Set(paginatedData.filter(row => (row.Type === selectedType)).map(row => row.InstrumentCode))))
    }
  };

  const instrumentSelected = (selectedInstrumentCode) => {
    if (selectedInstrumentCode === "CODE") {
      setExchangesArray(Array.from(new Set(paginatedData.map(row => row.Exchange))))
      setTypesArray(Array.from(new Set(paginatedData.map(row => row.Type))))
    } else {
      setExchangesArray(Array.from(new Set(paginatedData.filter(row => row.InstrumentCode === selectedInstrumentCode).map(row => row.Exchange))))
      setTypesArray(Array.from(new Set(paginatedData.filter(row => (row.InstrumentCode === selectedInstrumentCode)).map(row => row.Type))))
    }
  };

  const Calender3 = ({ startDate, endDate, setStartDate, setEndDate }) => {
    const handleStartDateChange = (e) => {
      const newStartDate = e.target.value;
      setStartDate(newStartDate);

      // Use the updated startDate in the callback of setStartDate
      onStartDateChange(newStartDate);

      // Perform other actions that depend on the updated state here
      setExchangesArray(Array.from(new Set(paginatedData.filter((row) => new Date(row.Date) >= new Date(newStartDate)).map(row => row.Exchange))));
    };

    const handleEndDateChange = (e) => {
      const newEndDate = e.target.value;
      setEndDate(newEndDate);

      // Use the updated endDate in the callback of setEndDate
      onEndDateChange(newEndDate);

      // Perform other actions that depend on the updated state here
      setExchangesArray(Array.from(new Set(paginatedData.filter((row) => new Date(row.Date) <= new Date(newEndDate)).map(row => row.Exchange))));
    };



    return (
      <>
        <section className="relative z-10 bg-gray-2 py-[20px]">
          <div className="mx-auto px-4 lg:container flex items-center justify-center h-full">
            <div className="mx-auto w-full max-w-[370px] flex items-center">
              <input
                type="date"
                className="h-[50px] w-full rounded border border-stroke bg-white px-5 outline-none focus:border-primary"
                value={startDate}
                onChange={handleStartDateChange}
              />
            </div>
            <div className="mx-2">
              <span className="text-2xl">-</span>
            </div>
            <div className="mx-auto w-full max-w-[370px] flex items-center">
              <input
                type="date"
                className="h-[50px] w-full rounded border border-stroke bg-white px-5 outline-none focus:border-primary"
                value={endDate}
                onChange={handleEndDateChange}
              />
            </div>
          </div>
        </section>
      </>
    );
  };


  useEffect(() => {
    setSelectedExchange("EXCHANGE");
    setSelectedType("TYPE");
    setSelectedInstrumentCode("CODE");

    exchangeSelected("EXCHANGE");
    typeSelected("TYPE");
    instrumentSelected("CODE");
  }, [paginatedData]);

  return (
    <thead>
      <tr className='bg-[#F9FAFB] text-left'>
        {headers.map((header, index) => (
          <th
            className={`py-1 first:pl-11 last:pr-5 text-base font-medium uppercase text-dark `}
            key={index}
          >
            {header.name === 'Exchange' && (
              <>
                {distinctExchangeValues.length > 1 && (
                  <SingleSidebarCard>
                    <div className="relative mr-7">
                      <select
                        value={selectedExchange}
                        onChange={handleSelectedExchange}
                        className="border-form-stroke text-body-color focus:border-primary 
                      active:border-primary w-full appearance-none rounded-lg border-[1.5px] py-3 px-5 
                      font-medium outline-none transition disabled:cursor-default disabled:bg-[#F5F7FD] fixed-width-dropdown"
                      >
                        <option>EXCHANGE</option>
                        {exchangesArray.map((exchange, index) => (
                          <option key={index} value={exchange}>
                            {exchange}
                          </option>
                        ))}
                      </select>
                      <span className="border-body-color absolute right-4 top-1/2 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2"></span>
                    </div>
                  </SingleSidebarCard>
                )}
              </>
            )}
            {header.name === 'Date' && (
              <>
                {distinctTypeValues.length > 1 && (
                  <SingleSidebarCard>
                    <div className="relative mr-7">
                      <select
                        value={selectedType}
                        onChange={handleSelectedType}
                        className="border-form-stroke text-body-color focus:border-primary 
                      active:border-primary w-full appearance-none rounded-lg border-[1.5px] py-3 px-5 
                      font-medium outline-none transition disabled:cursor-default disabled:bg-[#F5F7FD] fixed-width-dropdown"
                      >
                        <option>TYPE</option>
                        {typesArray.map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                      <span className="border-body-color absolute right-4 top-1/2 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2"></span>
                    </div>
                  </SingleSidebarCard>
                )}
              </>
            )}
            {header.name === 'Ex-Date' && (
              <>
                {distinctInstrumentCodeValues.length > 1 && (
                  <SingleSidebarCard>
                    <div className="relative mr-7">
                      <select
                        value={selectedInstrumentCode}
                        onChange={handleSelectedInstrumentCode}
                        className="border-form-stroke text-body-color focus:border-primary 
                      active:border-primary w-full appearance-none rounded-lg border-[1.5px] py-3 px-5 
                      font-medium outline-none transition disabled:cursor-default disabled:bg-[#F5F7FD] fixed-width-dropdown"
                      >
                        <option>CODE</option>
                        {instrumentCodeArray.map((code, index) => (
                          <option key={index} value={code}>
                            {code}
                          </option>
                        ))}
                      </select>
                      <span className="border-body-color absolute right-4 top-1/2 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2"></span>
                    </div>

                  </SingleSidebarCard>

                )}
              </>
            )}
            {header.name === 'Eligibility Date' && (
              <>
                <div className="relative mr-7 w-full">

                  <Calender3
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                  />
                </div>

              </>

            )

            }
            {header.name === 'Rate' && (
              <>
                <div className="items-center justify-between rounded-lg bg-[#F8F9FD]  md:flex">
                  <div className="mb-4 w-full md:mb-0 md:max-w-[220px] lg:max-w-[415px]">
                  </div>
                </div>
              </>
            )}
          </th>
        ))}
        <div className="items-center justify-between rounded-lg bg-[#F8F9FD] py-7 px-5 md:flex">
          <div className="w-full">
            <div className="items-center space-y-4 sm:flex sm:space-y-0 sm:space-x-6 md:justify-end">
              <button
                className="flex h-[50px] items-center justify-center rounded-md border border-stroke bg-white px-4"
                onClick={downloadCSV}>
                <span>
                  <path
                    d='M16.5 2.25L17.2636 2.89573C17.515 2.59845 17.571 2.18229 17.4071 1.82912C17.2433 1.47596 16.8893 1.25 16.5 1.25V2.25ZM1.5 2.25V1.25C1.11067 1.25 0.756741 1.47596 0.592882 1.82912C0.429024 2.18229 0.485033 2.59845 0.73643 2.89573L1.5 2.25ZM7.5 9.345H8.5C8.5 9.10858 8.41623 8.8798 8.26357 8.69928L7.5 9.345ZM7.5 14.25H6.5C6.5 14.6288 6.714 14.975 7.05279 15.1444L7.5 14.25ZM10.5 15.75L10.0528 16.6444C10.3628 16.7994 10.7309 16.7829 11.0257 16.6007C11.3205 16.4184 11.5 16.0966 11.5 15.75H10.5ZM10.5 9.345L9.73643 8.69928C9.58377 8.8798 9.5 9.10858 9.5 9.345H10.5ZM16.5 1.25H1.5V3.25H16.5V1.25ZM0.73643 2.89573L6.73643 9.99073L8.26357 8.69928L2.26357 1.60427L0.73643 2.89573ZM6.5 9.345V14.25H8.5V9.345H6.5ZM7.05279 15.1444L10.0528 16.6444L10.9472 14.8556L7.94721 13.3556L7.05279 15.1444ZM11.5 15.75V9.345H9.5V15.75H11.5ZM11.2636 9.99073L17.2636 2.89573L15.7364 1.60427L9.73643 8.69928L11.2636 9.99073Z'
                    fill='#637381'
                  />
                </span>
                Download CSV
              </button>
            </div>
          </div>
        </div>
      </tr>
    </thead>

  );
};

const TableBottom = ({ handlePreviousPage, handleNextPage, currentPage, totalPages }) => {
  return (
    <div className='flex items-center justify-between p-5 sm:px-6'>
      <div className='flex items-center justify-end space-x-3'>
        <button 
          onClick={handlePreviousPage} 
          disabled={currentPage === 1} 
          className={`flex h-[30px] w-[30px] items-center justify-center rounded border border-stroke bg-[#FAFBFF] text-body-color hover:border-primary hover:bg-primary hover:text-white ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : ''}`}>
          <svg
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M14.25 10C14.8023 10 15.25 9.55228 15.25 9C15.25 8.44771 14.8023 8 14.25 8L14.25 10ZM3.75 9L3.04289 8.29289C2.65237 8.68342 2.65237 9.31658 3.04289 9.70711L3.75 9ZM8.29289 14.9571C8.68342 15.3476 9.31658 15.3476 9.70711 14.9571C10.0976 14.5666 10.0976 13.9334 9.70711 13.5429L8.29289 14.9571ZM9.7071 4.45711C10.0976 4.06658 10.0976 3.43342 9.7071 3.04289C9.31658 2.65237 8.68342 2.65237 8.29289 3.04289L9.7071 4.45711ZM14.25 8L3.75 8L3.75 10L14.25 10L14.25 8ZM9.70711 13.5429L4.4571 8.29289L3.04289 9.70711L8.29289 14.9571L9.70711 13.5429ZM4.4571 9.70711L9.7071 4.45711L8.29289 3.04289L3.04289 8.29289L4.4571 9.70711Z'
              fill='currentColor'
            />
          </svg>
        </button>
        <button 
          onClick={handleNextPage} 
          disabled={currentPage === totalPages} 
          className={`flex h-[30px] w-[30px] items-center justify-center rounded border border-stroke bg-[#FAFBFF] text-body-color hover:border-primary hover:bg-primary hover:text-white ${currentPage === totalPages ? 'cursor-not-allowed opacity-50' : ''}`}>
          <svg
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M3.75 8C3.19772 8 2.75 8.44772 2.75 9C2.75 9.55229 3.19772 10 3.75 10V8ZM14.25 9L14.9571 9.70711C15.3476 9.31658 15.3476 8.68342 14.9571 8.29289L14.25 9ZM9.70711 3.04289C9.31658 2.65237 8.68342 2.65237 8.29289 3.04289C7.90237 3.43342 7.90237 4.06658 8.29289 4.45711L9.70711 3.04289ZM8.29289 13.5429C7.90237 13.9334 7.90237 14.5666 8.29289 14.9571C8.68342 15.3476 9.31658 15.3476 9.70711 14.9571L8.29289 13.5429ZM3.75 10H14.25V8H3.75V10ZM8.29289 4.45711L13.5429 9.70711L14.9571 8.29289L9.70711 3.04289L8.29289 4.45711ZM13.5429 8.29289L8.29289 13.5429L9.70711 14.9571L14.9571 9.70711L13.5429 8.29289Z'
              fill='currentColor'
            />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default Table2;
