import SEO from "./Components/SEO";
import React from 'react'
import Navbar4 from './Components/Nav4'
import Header from './Components/Hero'
import Map from './Components/Map'
import Service2 from './Components/ServiceStyle2'
import Footer2 from './Components/Footer'
import Announcements from './Components/Annoucements'
import Intercom from '@intercom/messenger-js-sdk';

Intercom({
  app_id: 'c0xn9g4v',
});

function Home(){
    return(
        <div>
            <SEO
                title={'Peresec Client Resources'}
                description={'Trading resources from Peresec to help your investing journey'}
            />
            <Navbar4/>
            <Header 
                title={'Peresec Client Resources'}
                paragraph={'Peresec has developed a suite of resources to enhance your trading experience.'}
            />
            <Service2
                h2={'Browse Our Useful Tools'}
                paragraph={'Browse through the useful tools and services we have to offer.'}
                Card1Title={'Global Bank Holidays'}
                href1={'bank-holidays'}
                Card1Desc={"Find a list of the bank and exchange holidays for over 100 countries. The list includes the UK, United States, Europe, Asia and South America."}
                Card2Title={'Withholdings Tax'}
                href2={'withholding-tax'}
                Card2Desc={"Query the withholding tax on your investments as a South African, Jersey, Guernsey or Mauritius resident."}
                Card3Title={'Market Information'}
                href3={'persec-offshore-markets'}
                Card3Desc={"Find detailed information about each of the markets that Peresec trades in."}
            />
            <Map/>
            <Announcements/>
            <Footer2/>
        </div>
    )
}

export default Home;