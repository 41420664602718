import React, { useState, useEffect  } from 'react';
import ReactCountryFlag from 'react-country-flag';
import Papa from 'papaparse';

const headers = [
  { name: 'Country', styles: 'min-w-[210px]' },
  { name: 'Holiday', styles: 'min-w-[140px]' },
  { name: 'Date', styles: 'min-w-[120px]' },
  { name: 'Day', styles: 'min-w-[120px]' },
  { name: 'Bank', styles: 'min-w-[130px]' },
  { name: 'Stock Exchange', styles: 'min-w-[130px]' },
  { name: 'Central Depository', styles: 'min-w-[130px]' },
  { name: 'Additional Information', styles: 'min-w-[140px]' },
];


const Table2 = (
  { tableData }
) => {
  
  const [selectedCountry, setSelectedCountry] = useState('Countries (All)');
  const [startDate, setStartDate] = useState(getFormattedCurrentDate()); // Set the default start date
  const [endDate, setEndDate] = useState(getEndOfYear());
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalItems = filteredData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    if (tableData && tableData.length > 0) {
      setFilteredData(tableData);
    }
  }, [tableData]);

  useEffect(() => {
    setEndDate(getEndOfYear());
  }, [new Date().getFullYear()]); 

  function getEndOfYear() {
    const currentYear = new Date().getFullYear();
    return `${currentYear}-12-31`;
  }

  function getFormattedCurrentDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const paginateData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredData.slice(startIndex, endIndex);
  };

  const handleNextPage = () => {

    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  
  const paginatedData = paginateData();
  console.log('pagination data',paginatedData)
  const convertToCSV = (data) => {
    const csv = Papa.unparse(data);
    return csv;
  }

  const handleFilter = () => {
    let filteredResult = tableData;



    // Filter by selected country
    if (selectedCountry !== 'Countries (All)') {
      filteredResult = filteredResult.filter((row) => row.Country === selectedCountry);
    }

    // Filter by date range
    if (startDate) {
      filteredResult = filteredResult.filter(
        (row) => new Date(row.Date) >= new Date(startDate)
      );
    }

        // If an end date is chosen, filter by it
        if (endDate) {
          filteredResult = filteredResult.filter(
            (row) => new Date(row.Date) <= new Date(endDate)
          );
        }
    
    setFilteredData(filteredResult);


  };

  const downloadCSV = () => {
    const csvData = convertToCSV(filteredData); // Replace with your data
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
  
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'holidays.csv'; // Set the desired file name
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  return (
    <section id='table' className='bg-white lg:py-[20px]'>
      <div className='container mx-auto'>
        <div className='-mx-4 flex flex-wrap'>
          <div className='w-full px-4'>
            <div className='max-w-full overflow-x-auto rounded-xl shadow-lg'>
              <TableTop
                tableData={tableData}
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                handleFilter={handleFilter}
                downloadCSV={downloadCSV}
              />
              <table className='max-w-full table-auto'>
                <TableHead headers={headers} />
                <TableBody data={paginatedData} />
                <TableBottom
                        handlePreviousPage={handlePreviousPage}
                        handleNextPage={handleNextPage}
                        currentPage={currentPage}
                        totalPages={totalPages}
                />
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const TableHead = ({ headers }) => {

  return (
    <thead>
      <tr className='bg-[#F9FAFB] text-left'>
        {headers.map((header, index) => (
          <th
            className={`py-4 px-4 first:pl-11 last:pr-11 text-base font-medium uppercase text-dark ${header.styles}`}
            key={index}
          >
            {header.name}
          </th>
        ))}
      </tr>
    </thead>
  );
};

const Calender3 = ({ startDate, endDate, setStartDate, setEndDate }) => {
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  return (
    <>
      <section className="relative z-10 bg-gray-2 py-[20px]">
        <div className="mx-auto px-4 lg:container flex items-center justify-center h-full">
          <div className="mx-auto w-full max-w-[370px] flex items-center">
            <input
              type="date"
              className="h-[50px] w-full rounded border border-stroke bg-white px-5 outline-none focus:border-primary"
              value={startDate}
              onChange={handleStartDateChange}
            />
          </div>
          <div className="mx-2">
            <span className="text-2xl">-</span>
          </div>
          <div className="mx-auto w-full max-w-[370px] flex items-center">
            <input
              type="date"
              className="h-[50px] w-full rounded border border-stroke bg-white px-5 outline-none focus:border-primary"
              value={endDate}
              onChange={handleEndDateChange}
            />
          </div>
        </div>
      </section>
    </>
  );
};

const TableBody = ({ data }) => {
  const [pageSize, setPageSize] = useState(10);
  const sliced = data.slice(0, pageSize);

  return (
    <tbody>
      {sliced.map((row, index) => (
        <tr key={index}>
          <td className='border-t border-[#eee] py-5 px-4 pl-11'>
            <div className='flex items-center'>
              <ReactCountryFlag
                className='h-45 mr-4 w-11 rounded-full'
                countryCode={row.Code}
                svg
                style={{
                  width: '3em',
                  height: '3em',
                }}
                title="US"
              />
              <div>
                <h5 className='text-dark text-sm font-medium'>{row.Country}</h5>
              </div>
            </div>
          </td>
          <td className='border-t border-[#eee]'>
            <p className='text-body-color text-sm'>{row.Holiday}</p>
          </td>
          <td className='border-t border-[#eee] py-5 px-4'>
            <p className='text-body-color text-sm'>{row.Date}</p>
          </td>
          <td className='border-t border-[#eee] py-5 px-4'>
            <p className='text-body-color text-sm'>{row.Day}</p>
          </td>
          <td className='border-t border-[#eee] py-5 px-4'>
            {row.Bank.includes('Open') ? (
              <span className='inline-flex items-center justify-center rounded-full bg-[#34D399] bg-opacity-[15%] py-2 px-4 text-xs font-semibold text-[#34D399]'>
                {row.Bank}
              </span>
            ) : (
              <span className='inline-flex items-center justify-center rounded-full bg-red-600 bg-opacity-[15%] py-2 px-4 text-xs font-semibold text-red-600'>
                {row.Bank}
              </span>
            )}
          </td>
          <td className='border-t border-[#eee] py-5 px-4'>
            {row.StockExchange.includes('Open') ? (
              <span className='inline-flex items-center justify-center rounded-full bg-[#34D399] bg-opacity-[15%] py-2 px-4 text-xs font-semibold text-[#34D399]'>
                {row.StockExchange}
              </span>
            ) : (
              <span className='inline-flex items-center justify-center rounded-full bg-red-600 bg-opacity-[15%] py-2 px-4 text-xs font-semibold text-red-600'>
                {row.StockExchange}
              </span>
            )}
          </td>
          <td className='border-t border-[#eee] py-5 px-4'>
            {row.CentralDepository.includes('Open') ? (
              <span className='inline-flex items-center justify-center rounded-full bg-[#34D399] bg-opacity-[15%] py-2 px-4 text-xs font-semibold text-[#34D399]'>
                {row.CentralDepository}
              </span>
            ) : (
              <span className='inline-flex items-center justify-center rounded-full bg-red-600 bg-opacity-[15%] py-2 px-4 text-xs font-semibold text-red-600'>
                {row.CentralDepository}
              </span>
            )}
          </td>
          <td className='border-t border-[#eee]'>
            <p className='text-body-color text-sm'>{row.AdditionalInformation}</p>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

const TableTop = ({
  tableData,
  selectedCountry,
  setSelectedCountry,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleFilter,
  downloadCSV
}) => {
  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  return (
    <div className="items-center justify-between rounded-lg bg-[#F8F9FD] py-4 px-5 md:flex">
      <div className="mb-4 w-full md:mb-0 md:max-w-[220px] lg:max-w-[415px]">
        <div className="relative">
          <select
            className="border-form-stroke text-body-color focus:border-primary active:border-primary w-full appearance-none rounded-lg border-[1.5px] py-3 px-5 font-medium outline-none transition disabled:cursor-default disabled:bg-[#F5F7FD]"
            onChange={handleCountryChange}
            value={selectedCountry}
          >
            <option value="Countries (All)">Countries (All)</option>
            {[...new Set(tableData.map((row) => row.Country))]
              .sort() 
              .map((countryName, index) => (
                <option key={index}>{countryName}</option>
            ))}
          </select>
          <span className="border-body-color absolute right-4 top-1/2 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2"></span>
        </div>
      </div>
      <div className="w-full">
        <div className="items-center space-y-4 sm:flex sm:space-y-0 sm:space-x-6 md:justify-end">
          <Calender3
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
          <button
            className="flex h-[50px] items-center justify-center rounded-md border border-stroke bg-white px-5"
            onClick={handleFilter}
          >
            <span>
              <path
                d='M16.5 2.25L17.2636 2.89573C17.515 2.59845 17.571 2.18229 17.4071 1.82912C17.2433 1.47596 16.8893 1.25 16.5 1.25V2.25ZM1.5 2.25V1.25C1.11067 1.25 0.756741 1.47596 0.592882 1.82912C0.429024 2.18229 0.485033 2.59845 0.73643 2.89573L1.5 2.25ZM7.5 9.345H8.5C8.5 9.10858 8.41623 8.8798 8.26357 8.69928L7.5 9.345ZM7.5 14.25H6.5C6.5 14.6288 6.714 14.975 7.05279 15.1444L7.5 14.25ZM10.5 15.75L10.0528 16.6444C10.3628 16.7994 10.7309 16.7829 11.0257 16.6007C11.3205 16.4184 11.5 16.0966 11.5 15.75H10.5ZM10.5 9.345L9.73643 8.69928C9.58377 8.8798 9.5 9.10858 9.5 9.345H10.5ZM16.5 1.25H1.5V3.25H16.5V1.25ZM0.73643 2.89573L6.73643 9.99073L8.26357 8.69928L2.26357 1.60427L0.73643 2.89573ZM6.5 9.345V14.25H8.5V9.345H6.5ZM7.05279 15.1444L10.0528 16.6444L10.9472 14.8556L7.94721 13.3556L7.05279 15.1444ZM11.5 15.75V9.345H9.5V15.75H11.5ZM11.2636 9.99073L17.2636 2.89573L15.7364 1.60427L9.73643 8.69928L11.2636 9.99073Z'
                fill='#637381'
              />
            </span>
            Search
          </button>
          <button
            className="flex h-[50px] items-center justify-center rounded-md border border-stroke bg-white px-5"
            onClick={downloadCSV}          >
            <span>
              <path
                d='M16.5 2.25L17.2636 2.89573C17.515 2.59845 17.571 2.18229 17.4071 1.82912C17.2433 1.47596 16.8893 1.25 16.5 1.25V2.25ZM1.5 2.25V1.25C1.11067 1.25 0.756741 1.47596 0.592882 1.82912C0.429024 2.18229 0.485033 2.59845 0.73643 2.89573L1.5 2.25ZM7.5 9.345H8.5C8.5 9.10858 8.41623 8.8798 8.26357 8.69928L7.5 9.345ZM7.5 14.25H6.5C6.5 14.6288 6.714 14.975 7.05279 15.1444L7.5 14.25ZM10.5 15.75L10.0528 16.6444C10.3628 16.7994 10.7309 16.7829 11.0257 16.6007C11.3205 16.4184 11.5 16.0966 11.5 15.75H10.5ZM10.5 9.345L9.73643 8.69928C9.58377 8.8798 9.5 9.10858 9.5 9.345H10.5ZM16.5 1.25H1.5V3.25H16.5V1.25ZM0.73643 2.89573L6.73643 9.99073L8.26357 8.69928L2.26357 1.60427L0.73643 2.89573ZM6.5 9.345V14.25H8.5V9.345H6.5ZM7.05279 15.1444L10.0528 16.6444L10.9472 14.8556L7.94721 13.3556L7.05279 15.1444ZM11.5 15.75V9.345H9.5V15.75H11.5ZM11.2636 9.99073L17.2636 2.89573L15.7364 1.60427L9.73643 8.69928L11.2636 9.99073Z'
                fill='#637381'
              />
            </span>
            Download CSV
          </button>
        </div>
      </div>
    </div>
  );
};

const TableBottom = ({ handlePreviousPage, handleNextPage, currentPage, totalPages }) => {
  return (
    <div className='flex items-center justify-between p-5 sm:px-6'>
      <div className='flex items-center justify-end space-x-3'>
        <button onClick={handlePreviousPage} disabled={currentPage === 1} className='flex h-[30px] w-[30px] items-center justify-center rounded border border-stroke bg-[#FAFBFF] text-body-color hover:border-primary hover:bg-primary hover:text-white'>
          <svg
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M14.25 10C14.8023 10 15.25 9.55228 15.25 9C15.25 8.44771 14.8023 8 14.25 8L14.25 10ZM3.75 9L3.04289 8.29289C2.65237 8.68342 2.65237 9.31658 3.04289 9.70711L3.75 9ZM8.29289 14.9571C8.68342 15.3476 9.31658 15.3476 9.70711 14.9571C10.0976 14.5666 10.0976 13.9334 9.70711 13.5429L8.29289 14.9571ZM9.7071 4.45711C10.0976 4.06658 10.0976 3.43342 9.7071 3.04289C9.31658 2.65237 8.68342 2.65237 8.29289 3.04289L9.7071 4.45711ZM14.25 8L3.75 8L3.75 10L14.25 10L14.25 8ZM9.70711 13.5429L4.4571 8.29289L3.04289 9.70711L8.29289 14.9571L9.70711 13.5429ZM4.4571 9.70711L9.7071 4.45711L8.29289 3.04289L3.04289 8.29289L4.4571 9.70711Z'
              fill='currentColor'
            />
          </svg>
        </button>
        <button onClick={handleNextPage} disabled={currentPage === totalPages} className='flex h-[30px] w-[30px] items-center justify-center rounded border border-stroke bg-[#FAFBFF] text-body-color hover:border-primary hover:bg-primary hover:text-white'>
          <svg
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M3.75 8C3.19772 8 2.75 8.44772 2.75 9C2.75 9.55229 3.19772 10 3.75 10V8ZM14.25 9L14.9571 9.70711C15.3476 9.31658 15.3476 8.68342 14.9571 8.29289L14.25 9ZM9.70711 3.04289C9.31658 2.65237 8.68342 2.65237 8.29289 3.04289C7.90237 3.43342 7.90237 4.06658 8.29289 4.45711L9.70711 3.04289ZM8.29289 13.5429C7.90237 13.9334 7.90237 14.5666 8.29289 14.9571C8.68342 15.3476 9.31658 15.3476 9.70711 14.9571L8.29289 13.5429ZM3.75 10H14.25V8H3.75V10ZM8.29289 4.45711L13.5429 9.70711L14.9571 8.29289L9.70711 3.04289L8.29289 4.45711ZM13.5429 8.29289L8.29289 13.5429L9.70711 14.9571L14.9571 9.70711L13.5429 8.29289Z'
              fill='currentColor'
            />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default Table2;
