import React, { useState, useEffect, useRef } from "react";

const Calendar2 = (
  { tableData }
) => {

  const [calendarData, setFilteredData] = useState(tableData || []);

  console.log('calendar data', calendarData);
  console.log('table data', tableData);

  useEffect(() => {
    setFilteredData(tableData);
  }, [tableData]);

  // Set automatically handles removing duplicates
  const countries = new Set();

  // Iterate through calendarData and add countries to Set
  calendarData.forEach(row => {
    countries.add(row.Country);
  });

  // Convert Set to an array and sort alphabetically.
  const sortedCountries = [...countries].sort();

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so we add 1.

  // Format the current month with leading zeros (e.g., "01" for January).
  const formattedCurrentMonth = currentMonth.toString().padStart(2, '0');

  // Initial value: current year and month
  const initialDate = `${currentYear}-${formattedCurrentMonth}`;

  const [selectedMonth, setSelectedMonth] = useState(initialDate);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [daysInMonth, setDaysInMonth] = useState(0); // Number of days in the selected month

  const [expandedEventTitles, setExpandedEventTitles] = useState({});

  const calendarRef = useRef(null);



  useEffect(() => {
    // Check if tableData is available
    if (tableData && tableData.length > 0) {
      // Filter the events based on the selected month and country
      if (selectedMonth !== "" || selectedCountry === "") {
        const [year, month] = selectedMonth.split("-");
        const eventsForSelectedMonth = tableData.filter((event) => {
          const eventDate = new Date(event.Date);
          const isSameMonth = eventDate.getFullYear() === parseInt(year) && eventDate.getMonth() + 1 === parseInt(month);
          const isMatchingCountry = selectedCountry === "" || event.Country === selectedCountry;
          return isSameMonth && isMatchingCountry;
        });
        setFilteredEvents(eventsForSelectedMonth);
  
        // Calculate the number of days in the selected month
        const daysInSelectedMonth = new Date(parseInt(year), parseInt(month), 0).getDate();
        setDaysInMonth(daysInSelectedMonth);
      }
    }
  }, [selectedMonth, selectedCountry, tableData]);
  

  // Get the day of the week for the first day of the selected month
  const firstDayOfMonth = new Date(selectedYear, parseInt(selectedMonth.split("-")[1]) - 1, 1).getDay();

  // Generate the calendar grid with empty cells before the first day
  const daysOfMonth = Array(firstDayOfMonth).fill(null);
  const eventsByDate = {};

  filteredEvents.forEach(event => {
    const date = new Date(event.Date);
    const eventDay = date.getDate();
    const formattedDate = `${selectedYear}-${selectedMonth}-${eventDay.toString().padStart(2, '0')}`;

    if (eventsByDate[formattedDate]) {
      eventsByDate[formattedDate].eventTitles.push(event.Country + ': ' + event.Holiday);
    } else {
      eventsByDate[formattedDate] = {
        date: formattedDate,
        eventTitles: [event.Country + ': ' + event.Holiday],
      };
    }
  });

  // Generate the calendar grid with correct number of days
  for (let dayNumber = 1; dayNumber <= daysInMonth; dayNumber++) {
    const formattedDate = `${selectedYear}-${selectedMonth}-${dayNumber.toString().padStart(2, '0')}`;


    daysOfMonth.push({
      number: dayNumber,
      eventTitles: eventsByDate[formattedDate] ? eventsByDate[formattedDate].eventTitles : [],
      eventDate: formattedDate,
    });
  }

  const weeks = [];
  while (daysOfMonth.length > 0) {
    weeks.push(daysOfMonth.splice(0, 7));
  }

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        // Clicked outside the calendar container
        setExpandedEventTitles({});
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  return (
    <>
      <section className="relative z-10 bg-white py-[120px]">
        <div className="mx-auto px-4 lg:container" ref={calendarRef}>
          <div className="mb-[30px] flex items-center justify-between rounded-lg border border-stroke bg-gray p-4 pl-5">
            <p className="text-base font-semibold text-black sm:text-xl">
              <Calendar3 onMonthChange={setSelectedMonth} initialMonth={initialDate} />
            </p>
            <div className="relative z-20 inline-flex rounded bg-white">
              <select
                name=""
                id=""
                className="relative z-20 h-11 appearance-none rounded border border-stroke bg-transparent pr-9 pl-5 outline-none"
                onChange={(e) => setSelectedCountry(e.target.value)}
                value={selectedCountry}
              >
                <option value=''>Countries (All)</option>
                {sortedCountries.map(country => (
                  <option key={country}>{country}</option>
                ))}

              </select>

              <span className="absolute right-4 top-1/2 z-10 -translate-y-1/2">
                <svg
                  width="11"
                  height="6"
                  viewBox="0 0 11 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.49652 5.57195C5.29784 5.57195 5.09917 5.50239 4.93361 5.34588L0.496609 0.963677C0.380718 0.841949 0.380718 0.650662 0.480054 0.528935C0.595945 0.407207 0.778061 0.407207 0.893953 0.511545L5.33096 4.89375C5.41374 4.9807 5.56274 4.9807 5.66208 4.89375L10.0991 0.511545C10.215 0.389817 10.3971 0.407207 10.513 0.528935C10.6289 0.650662 10.6123 0.841949 10.4964 0.963677L6.05942 5.32849C5.89386 5.485 5.69519 5.57195 5.49652 5.57195Z"
                    fill="#212B36"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.65444 5.65773L0.212873 1.27101L0.208441 1.26636C-0.0635564 0.980663 -0.0621445 0.535609 0.170632 0.250361L0.180818 0.237879L0.191885 0.226255C0.463883 -0.0594399 0.887599 -0.0579557 1.15917 0.186543L1.16629 0.192953L5.49652 4.4697L9.81622 0.203348C10.1204 -0.109866 10.5585 -0.0286158 10.8011 0.226255C11.1055 0.545928 11.0287 1.01 10.7846 1.26636L10.7799 1.27131L6.3321 5.64672C6.10301 5.86329 5.8101 6 5.49652 6C5.20451 6 4.90666 5.89629 4.66099 5.66405L4.65444 5.65773ZM10.0991 0.511545L5.66208 4.89375C5.56274 4.9807 5.41374 4.9807 5.33096 4.89375L0.893953 0.511545C0.778061 0.407207 0.595945 0.407207 0.480054 0.528935C0.380718 0.650662 0.380718 0.841949 0.496609 0.963677L4.93361 5.34588C5.09917 5.50239 5.29784 5.57195 5.49652 5.57195C5.69519 5.57195 5.89386 5.485 6.05942 5.32849L10.4964 0.963677C10.6123 0.841949 10.6289 0.650662 10.513 0.528935C10.3971 0.407207 10.215 0.389817 10.0991 0.511545Z"
                    fill="#212B36"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="w-full max-w-full bg-white">
            <table className="w-full">
              <thead>
                <tr className="rounded-t bg-primary text-white">
                  {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day, index) => (
                    <th
                      key={index}
                      className={`first:rounded-tl last:rounded-tr h-10 w-10 p-2 text-xs lg:w-28 xl:text-sm 2xl:w-40 ${index === 0 ? "text-white" : ""
                        }`}
                    >
                      <span className="flex justify-center">{day}</span>
                    </th>
                  ))}                </tr>
              </thead>
              <tbody>
                {weeks.map((week, weekIndex) => (
                  <tr key={weekIndex} className="h-16 text-center">
                    {week.map((day, dayIndex) => (
                      <td
                        key={dayIndex}
                        className={`ease hover:bg-gray-300 relative h-20 w-10 cursor-pointer border border-[#e7e7e7] p-1 transition duration-500 ${day && day.eventTitles.length > 0 ? 'md:h-20' : 'md:h-20'} lg:w-20 2xl:w-10`}
                      >
                        {day && (
                          <div className="mx-auto flex h-20 w-10 flex-col overflow-hidden sm:w-full md:w-10 lg:w-10 2xl:w-10">
                            <div className="top h-22 w-full">
                              <span className="text-gray-500">{day.number}</span>
                            </div>
                            {day.eventTitles.length > 0 && (
                              <div
                                className="bottom group h-20 w-full flex-grow cursor-pointer py-1"
                                onClick={() => {
                                  setExpandedEventTitles({
                                    ...expandedEventTitles,
                                    [day.eventDate]: !expandedEventTitles[day.eventDate],
                                  });
                                }}
                              >
                                <span className="group-hover:text-primary md:hidden">More</span>
                                <div
                                  className={`event invisible absolute left-0 mb-0 w-full rounded border-l-[3px] border-primary bg-[#f4f7ff] px-2 py-0 text-left ${expandedEventTitles[day.eventDate] ? 'visible opacity-100' : 'opacity-0'
                                    } group-hover:visible group-hover:opacity-100 md:visible md:w-full md:opacity-100`}
                                  style={{ maxHeight: '45px', overflow: 'hidden' }}

                                >
                                  {day.eventTitles.length > 0 && (
                                    <div className="event-name">
                                      {day.eventTitles.map((title, index) => (
                                        <div
                                          key={index}
                                          style={{ fontSize: '10px', color: 'black', fontWeight: 'bold' }}
                                        >
                                          {title.split(':')[1]}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
};


const Calendar3 = ({ onMonthChange }) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so we add 1.

  // Format the current month with leading zeros (e.g., "01" for January).
  const formattedCurrentMonth = currentMonth.toString().padStart(2, '0');

  // Initial value: current year and month
  const initialDate = `${currentYear}-${formattedCurrentMonth}`;

  // State to track the selected month
  const [selectedMonth, setSelectedMonth] = useState(initialDate);

  // Function to handle month change
  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
    onMonthChange(e.target.value); // Send the selected month to the parent component
  };

  return (
    <>
      <div className="mx-auto w-full max-w-[370px] flex items-center">
        <input
          type="month"
          className="h-[50px] w-full rounded border border-stroke bg-white px-5 outline-none focus:border-primary"
          value={selectedMonth}
          onChange={handleMonthChange}
        />
      </div>
    </>
  );
};

export default Calendar2;
